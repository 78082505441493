import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import queryString from 'query-string';

import { withRouter } from '^/withRouter';
import { getSubGroupsForCurrentGroup } from '^/actions/actions';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import { getSelectedSubGroupMap } from '^/components/app/groups/insights/utils';
import PureComponent from '^/components/common/PureComponent';


export class SubGroupFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.updatePathWithSubGroupFilter = this.updatePathWithSubGroupFilter.bind(this);
  }

  componentWillMount() {
    this.props.getSubGroupsForCurrentGroup();
  }

  updatePathWithSubGroupFilter(subGroup) {
    const { pathname, country } = this.props;

    const queryParams = {
      sub_group: subGroup,
      country,
    }

    this.props.router.replace(`${pathname}${'?' + queryString.stringify(queryParams)}`);
  }

  onChange(event) {
    const value = event.target.value;
    this.updatePathWithSubGroupFilter(value);
    this.props.onChange(value);
  }

  render() {
    const { subGroups, selectedSubGroup, selectedSubGroupMap } = this.props;

    if (subGroups.isEmpty()) {
      return null;
    }

    return (
      <h4 className="mb-1 mt-1">View by Sub Group:
        <select
          className="insights-dropdown"
          value={selectedSubGroup}
          onChange={this.onChange}
        >
          <option key="" value="">--</option>
          {subGroups.map(subGroup =>
            <option key={subGroup.get('id')} value={subGroup.get('id')}>
              {subGroup.get('name')}
            </option>
          )}
        </select>
        <span className="print-dropdown-as-text">
          { selectedSubGroupMap.isEmpty() ? '--' : selectedSubGroupMap.get('name') }
        </span>
      </h4>
    );
  }
}

SubGroupFilter.propTypes = {
  insightsConfig: ImmutablePropTypes.list.isRequired,
  subGroups: ImmutablePropTypes.list.isRequired,
  pathname: React.PropTypes.string.isRequired,
  selectedSubGroup: React.PropTypes.string,
  selectedSubGroupMap: ImmutablePropTypes.map.isRequired
};

export function mapStateToProps(state, props) {
  const subGroups = state.subGroups;

  return {
    subGroups,
    selectedSubGroupMap: getSelectedSubGroupMap(subGroups, props.selectedSubGroup)
  }
}

const ConnectedSubGroupFilter = connect(
  mapStateToProps,
  { getSubGroupsForCurrentGroup }
)(withRouter(SubGroupFilter));

export default withFilters(ConnectedSubGroupFilter);
