import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { expandUiComponent, collapseUiComponent } from '^/actions/actions';

function UserInvited({eventItem}) {
  return (
    <p className="mt-1">
      <span className="bolder-text">{eventItem.time}</span>
      <span className="ml-1">Invited {eventItem.name}</span>
    </p>
  );
}

export function ExpandableSection({
  isExpanded,
  expand,
  collapse,
  events,
  groupingKey,
  practiceName
}) {
  return (
    <div className="insights-card mt-1-2">
      <button
        className={classNames(
          'no-border',
          'pl-0',
          'collapse-button',
          'display-flex',
          'flex-align-items-center',
          'text-left',
          'focus-outline-none',
          'focus-underline',
          'medium-text',
          !isExpanded && 'collapsed'
        )}
        onClick={isExpanded ? collapse : expand}
      >
        <span
          className="width-200px flex-shrink-0 text-overflow-ellipsis bolder-text"
          title={practiceName}
        >
          {practiceName}
        </span>
        <span
          className="flex-grow text-overflow-ellipsis pl-2 pr-2 light-text"
          title="Learn about the history, usage and variations of Lorem Ipsum, the industry's standard dummy text for printing and typesetting. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and model sentence structures."
        >
          Learn about the history, usage and variations of Lorem Ipsum, the industry's standard dummy text for printing and typesetting. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and model sentence structures.
        </span>
      </button>
      {isExpanded && (
        <div className="pl-1 pr-1 pb-1 background-lightest-gray mt-1 base-border-radius">
          {events.map((eventItem, index) => (
            <UserInvited
              key={`${groupingKey}-${index}`}
              eventItem={eventItem}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    isExpanded: state.ui.get('expandedUiComponents').contains(props.groupingKey),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    expand: () => dispatch(expandUiComponent(props.groupingKey)),
    collapse: () => dispatch(collapseUiComponent(props.groupingKey)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableSection);
