import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { getSubGroupsForCurrentGroup } from '^/actions/actions';
import { getAllCollection } from '^/actions/collections';
import Loading from '^/components/app/content/Loading';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  practiceInteractionInsightsConfig,
  FILTER_KEYS
} from '^/components/app/groups/insights/constants';
import DateRangeFilter from '^/components/app/groups/insights/filters/DateRangeFilter';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withSelectedFilters from '^/components/app/groups/insights/hoc/withSelectedFilters';
import { categoryOptions, typeOptions } from '^/components/app/groups/reports/constants'
import ExpandableSection from '^/components/app/groups/reports/ExpandableSection';
import FilterSelect from '^/components/app/groups/reports/FilterSelect';
import PureComponent from '^/components/common/PureComponent';
import { GROUP_PRACTICES } from '^/consts/collectionKeys';
import { MOMENT_UTC_TODAY, MOMENT_UTC_A_WEEK_AGO, formatBackendDate } from '^/utils';

const INSIGHT = INSIGHTS.PRACTICE_INTERACTION;

function ExpandableSections({isLoading, data}) {
  if (isLoading) {
    return <Loading />
  }

  if (!data || !data.length) {
    return (
      <p>No data found for these dates.</p>
    )
  }

  // TODO - add failed state here (400 when too many events)

  return (
    <div>
      {data.map(item => (
        <ExpandableSection
          key={item.key}
          groupingKey={item.key}
          events={item.events}
          practiceName={item.practice_name}
        />
      ))}
    </div>
  );
}

export class PracticeInteraction extends PureComponent {

  componentDidMount() {
    this.props.getAllCollection(GROUP_PRACTICES);
    this.props.getSubGroupsForCurrentGroup();
  }

  render () {
    const { data, subGroupOptions, practiceOptions, isLoading } = this.props;

    return (
      <div className="pl-1 pr-1 pt-1 pb-2">
        <div className="mb-2 row">
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Sub group"
              options={subGroupOptions ? subGroupOptions.toJS() : []}
              insightsConfig={practiceInteractionInsightsConfig}
              filterKey={FILTER_KEYS.SUB_GROUP}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Practice"
              options={practiceOptions ? practiceOptions.toJS() : []}
              insightsConfig={practiceInteractionInsightsConfig}
              filterKey={FILTER_KEYS.PRACTICE}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Category"
              options={categoryOptions}
              insightsConfig={practiceInteractionInsightsConfig}
              filterKey={FILTER_KEYS.CATEGORY}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Type"
              options={typeOptions}
              insightsConfig={practiceInteractionInsightsConfig}
              filterKey={FILTER_KEYS.TYPE}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-1-4 pt-2 mt-1-4">
            <DateRangeFilter
              insightsConfig={practiceInteractionInsightsConfig}
              dateFrom={this.props.dateFrom}
              dateTo={this.props.dateTo}
              isDisabled={isLoading}
            />
          </div>
        </div>
        <ExpandableSections isLoading={isLoading} data={data} />
      </div>
    );
  }
}

const getOptionsFromList = list => list.map(item => ({
  value: item.get('id'),
  label: item.get('name')
}));

export function mapStateToProps(state) {
  return {
    subGroupOptions: getOptionsFromList(state.subGroups),
    practiceOptions: getOptionsFromList(state.collections.getIn([GROUP_PRACTICES, 'items'], List()))
  }
}

const ConnectedPracticeInteraction = connect(mapStateToProps, {
  getAllCollection,
  getSubGroupsForCurrentGroup
})(PracticeInteraction);

const PracticeInteractionWithSelectedFilters = withSelectedFilters(
  INSIGHT
)(ConnectedPracticeInteraction);

export default withInsight({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.PRACTICE_INTERACTION,
  getFilters: () => (
    Map({
      date_from: formatBackendDate(MOMENT_UTC_A_WEEK_AGO),
      date_to: formatBackendDate(MOMENT_UTC_TODAY)
    })
  )
})(PracticeInteractionWithSelectedFilters);
