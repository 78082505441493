import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import Table from '^/components/app/groups/insights/visualisations/Table';
import TableFooter from '^/components/app/groups/insights/visualisations/TableFooter';
import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import withLoadInsightDataOnMount from '^/components/app/groups/insights/hoc/withLoadInsightDataOnMount';
import { INSIGHTS, INSIGHTS_API_URLS, INSIGHTS_CSV_API_URLS } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import {
  convertRateToPercentage,
  MOMENT_UTC_TODAY,
  MOMENT_UTC_A_YEAR_AGO
} from '^/utils';

const INSIGHT = INSIGHTS.EVENT_RECORDS_STATUS_BY_PRACTICE;

const COLUMNS = (onClick) => [
  {
    key: 'practice_name',
    header: 'Practice',
    className: 'text-align-left',
    cell: ({ row }) =>
      <div>
        <a onClick={() => onClick(row.get('practice_id'))}>
          {row.get('practice_name')}
        </a>
      </div>
  },
  {
    key: 'open_rate',
    header: 'Open',
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('open_rate'))} %`}</span>
  },
  {
    key: 'in_progress_rate',
    header: 'In progress',
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('in_progress_rate'))} %`}</span>
  },
  {
    key: 'closed_rate',
    header: 'Closed',
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('closed_rate'))} %`}</span>
  }
];

export const EventRecordsTable = (props) => (
  <div className="row">
    <div className="col-1">
      <Table
        insight={INSIGHT}
        columns={COLUMNS(props.changePracticeAndNavToDashboard)}
        defaultSortByKey="open_rate"
        defaultSortIsAscending={false}
      />
      <TableFooter
        insight={INSIGHT}
        csvExportButton={
          <a
            className="btn btn-default"
            href={INSIGHTS_CSV_API_URLS.EVENT_RECORDS_STATUS_BY_PRACTICE}
            target="_blank"
          >
            DOWNLOAD CSV
          </a>
        }
      />
    </div>
  </div>
);

const ConnectedEventRecordsTable = connect(
  undefined,
  { changePracticeAndNavToDashboard }
)(EventRecordsTable);

export default withLoadInsightDataOnMount({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.EVENT_RECORDS_STATUS_BY_PRACTICE,
  getFilters: () => (
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ConnectedEventRecordsTable);
