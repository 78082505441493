import React from 'react';

import { eventRecordInsightsConfig, FILTER_KEYS, PATHS } from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import EventRecordsTable from '^/components/app/groups/reports/EventRecordsTable';
import EventRecordsDetailCard from '^/components/app/groups/reports/EventRecordsDetailCard';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';

const EventRecordsPage = ({ location: { pathname, query } }) => (
  <div className="col-1">
    <Header
      shouldLinkToDashboard
      dashboardLink={PATHS.REPORTS_DASHBOARD}
      subtitle="Event records"
      query={query}
    />
    <div className="display-flex">
      <SubGroupFilter
        insightsConfig={eventRecordInsightsConfig}
        filterKey={FILTER_KEYS.SUB_GROUP}
        pathname={pathname}
        selectedSubGroup={query.sub_group}
        country={query.country}
      />
    </div>
    <EventRecordsDetailCard />
    <EventRecordsTable />
  </div>
);

export default EventRecordsPage;
