export const USERS = 'USERS';
export const ACTIVITIES = 'ACTIVITIES';
export const RESPONSIBILITIES = 'RESPONSIBILITIES';
export const TASKS = 'TASKS';
export const DOCUMENTS = 'DOCUMENTS';

export const categoryOptions = [
  {
    value: USERS,
    label: 'Users',
  },
  {
    value: ACTIVITIES,
    label: 'Activities',
  },
  {
    value: RESPONSIBILITIES,
    label: 'Responsibilities',
  },
  {
    value: TASKS,
    label: 'Tasks',
  },
  {
    value: DOCUMENTS,
    label: 'Documents',
  }
];

// Users
export const USER_INVITED = 'USER_INVITED';
export const USER_ACCEPTED = 'USER_ACCEPTED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_RETIRED = 'USER_RETIRED';
export const USER_SUSPENDED = 'USER_SUSPENDED';
export const USER_REJECTED = 'USER_REJECTED';
// Activities
export const ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED';
export const ACTIVITY_RESCHEDULED = 'ACTIVITY_RESCHEDULED';
export const ACTIVITY_REASSIGNED = 'ACTIVITY_REASSIGNED';
export const ACTIVITY_FILE_UPLOADED = 'ACTIVITY_FILE_UPLOADED';
// Responsibilities
export const RESPONSIBILITY_UPDATED = 'RESPONSIBILITY_UPDATED';
// Tasks
export const TASK_CREATED = 'TASK_CREATED';
export const TASK_RESCHEDULED = 'TASK_RESCHEDULED';
export const TASK_ASSIGNEES_CHANGED = 'TASK_ASSIGNEES_CHANGED';
export const TASK_DELETED = 'TASK_DELETED';
// Documents
export const DOCUMENT_VERSION_UPDATED = 'DOCUMENT_VERSION_UPDATED';
export const DOCUMENT_EDITED = 'DOCUMENT_EDITED';
export const DOCUMENT_COPY_CREATED = 'DOCUMENT_COPY_CREATED';

export const typeOptions = [
  // Users
  {
    value: USER_INVITED,
    label: 'User invited',
  },
  {
    value: USER_ACCEPTED,
    label: 'Invite accepted',
  },
  {
    value: USER_UPDATED,
    label: 'User updated',
  },
  {
    value: USER_RETIRED,
    label: 'User retired',
  },
  {
    value: USER_SUSPENDED,
    label: 'User suspended',
  },
  {
    value: USER_REJECTED,
    label: 'User rejected',
  },
  // Activities
  {
    value: ACTIVITY_COMPLETED,
    label: 'Activity completed',
  },
  {
    value: ACTIVITY_RESCHEDULED,
    label: 'Activity rescheduled',
  },
  {
    value: ACTIVITY_REASSIGNED,
    label: 'Activity reassigned',
  },
  {
    value: ACTIVITY_FILE_UPLOADED,
    label: 'File uploaded to activity',
  },
  // Responsibilities
  {
    value: RESPONSIBILITY_UPDATED,
    label: 'Responsibility updated',
  },
  // Tasks
  {
    value: TASK_CREATED,
    label: 'Task created',
  },
  {
    value: TASK_RESCHEDULED,
    label: 'Task created',
  },
  {
    value: TASK_ASSIGNEES_CHANGED,
    label: 'Task created',
  },
  {
    value: TASK_DELETED,
    label: 'Task created',
  },
  // Documents
  {
    value: DOCUMENT_VERSION_UPDATED,
    label: 'Document version updated',
  },
  {
    value: DOCUMENT_EDITED,
    label: 'Document edited',
  },
  {
    value: DOCUMENT_COPY_CREATED,
    label: 'Document copy created',
  },
];

export const DUMMY_DATA = [
  {
    key: 'practice-id-1USER_INVITEDactive-user-id-12024-09-01',
    practice_name: 'Bright Dental',
    events: [
      {
        time: '15:12',
        name: 'Some name 1'
      },
      {
        time: '12:45',
        name: 'Some name 4'
      }
    ]
  },
  {
    key: 'practice-id-2USER_INVITEDactive-user-id-22024-10-01',
    practice_name: 'Bright Smile - Padstow',
    events: [
      {
        time: '15:30',
        name: 'Some name 2'
      }
    ]
  },
  {
    key: 'practice-id-3USER_INVITEDactive-user-id-32024-05-01',
    practice_name: 'Bright Smile - Shoreham by Sea',
    events: [
      {
        time: '11:30',
        name: 'Some name 3'
      }
    ]
  }
]
