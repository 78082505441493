import React from 'react';

import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';

export class FilterSelect extends PureComponent {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { filterKey, options, label, isDisabled } = this.props;
    return (
      <div>
        <label className="display-block mb-1-4 light-text" htmlFor={filterKey}>{label}</label>
        <select
          className="form-select full-width"
          id={filterKey}
          onChange={this.onChange}
          disabled={isDisabled}
        >
          <option value="">All</option>
          {options.map(option => (
            <option key={`${option.value}`} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    );
  }
}

export default withFilters(FilterSelect);
