import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { getCurrentGroup } from '^/stateHelpers';
import { PATHS } from '^/components/app/groups/insights/constants';

export const Header = ({
  title,
  subtitle,
  shouldLinkToDashboard,
  dashboardLink,
  query,
  hasSubGroups
}) => (
  <div className={`mt-2 ${!hasSubGroups && "mb-3"}`}>
    <h1 className="m0">
      { shouldLinkToDashboard ?
        (<Link
          className="text-underlined"
          to={{ pathname: dashboardLink || PATHS.GROUPS_DASHBOARD, query }}
        >
          {title}
        </Link>) :
        title
      }
      {subtitle && (<span> - {subtitle}</span>)}
    </h1>
  </div>
);

export function mapStateToProps(state) {
  const currentGroup = getCurrentGroup(state);
  return {
    title: currentGroup ? currentGroup.get('name') : '',
    hasSubGroups: !(state.subGroups.isEmpty()),
  };
}

Header.propTypes = {
  hasSubGroups: React.PropTypes.bool.isRequired,
  shouldLinkToDashboard: React.PropTypes.bool,
  subtitle: React.PropTypes.string,
};

export default connect(mapStateToProps)(Header);
