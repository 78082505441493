import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { formatDateTime } from '^/utils';
import Icon from '^/components/app/content/Icon';
import PureComponent from '^/components/common/PureComponent';

export default class LandingCard extends PureComponent {
  render () {
    const {
      children,
      title,
      icon,
      link,
      linkText,
      hasFailedToLoad,
      className,
      lastUpdatedTimestamp,
      query
    } = this.props;
    return (
      <div className={className ? className : 'insights-card full-height'}>
        <h3>
          {title}
          {icon && <Icon type={icon} className="pull-right header-icon" />}
        </h3>
        { lastUpdatedTimestamp &&
          <p className="insights-timestamp">Updated: {formatDateTime(lastUpdatedTimestamp)}</p>
        }
        <div className="insights-card-content">
          {children}
        </div>

        <div className="row">
          {hasFailedToLoad && (
            <div className="col-1-3 mt-1 mb-1 no-padding-right">
              <p className="small-text align-left">
                Failed to load
              </p>
            </div>
          )}

          <div
            className={classNames('mt-1 mb-1 no-padding-left align-right', {
              'col-2-3': hasFailedToLoad,
              'col-1': !hasFailedToLoad
            })}
          >
            <Link className="insights-footer-link" to={{ pathname: link, query }}>
              {linkText && (
                <span>
                  {linkText}<Icon type="arrow-right" className="link-icon ml-1-2" />
                </span>
              )}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

LandingCard.propTypes = {
  title: React.PropTypes.string.isRequired,
  icon: React.PropTypes.string,
  link: React.PropTypes.string,
  linkText: React.PropTypes.string,
  hasFailedToLoad: React.PropTypes.bool,
  lastUpdatedTimestamp: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.object]),
  query: React.PropTypes.object,
};
